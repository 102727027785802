// 场景验证枚举
export enum SENCE {
  LOGIN = 'LOGIN', // 登录
  REGISTER = 'REGISTER', // 注册
  BIND_EMAIL = 'BIND_EMAIL', // 绑定邮箱 / 重置邮箱
  UNBIND_EMAIL = 'UNBIND_EMAIL', // 解绑邮箱
  BIND_PHONE = 'BIND_PHONE', // 绑定手机 / 重置手机
  UNBIND_PHONE = 'UNBIND_PHONE', // 解绑手机
  BIND_GA = 'BIND_GA', // 绑定谷歌验证器
  UNBIND_GA = 'UNBIND_GA', // 解绑谷歌验证器
  CREATE_TRANSFER = 'CREATE_TRANSFER', // 内部转账
  CREATE_WITHDRAW = 'CREATE_WITHDRAW', // 提币
  FORGOT_WITHDRAW = 'FORGOT_WITHDRAW', // 忘记资金密码
  CHANGE_WITHDRAW = 'CHANGE_WITHDRAW', // 修改资金密码
  UNBIND_WITHDRAW = 'UNBIND_WITHDRAW', // 关闭资金密码
  CHANGE_PASSWORD = 'CHANGE_PASSWORD', // 修改登录密码
  FORGOT_PASSWORD = 'FORGOT_PASSWORD', // 忘记登录密码
  DEACTIVATE_ACCOUNT = 'DEACTIVATE_ACCOUNT', // 禁用账户
  DELETE_ACCOUNT = 'DELETE_ACCOUNT', // 删除账户
  REACTIVATE_ACCOUNT = 'REACTIVATE_ACCOUNT', // 恢复账户
  CHANGE_PHISHING = 'CHANGE_PHISHING', // 防钓鱼
  UNBIND_CREDENTIAL = 'UNBIND_CREDENTIAL', // 解绑生物验证
  FIATSELL = 'CREATE_WITHDRAW',
  WITHDRAW_FAST = 'WITHDRAW_FAST', // 开启提币免验证
  WITHDRAW_WHITE = 'WITHDRAW_WHITE', // 开启提币白名单
  CHANGE_ADDRESS_WHITE = 'CHANGE_ADDRESS_WHITE', // 更改提币地址白名单
  CREATE_C2C_PAYMENT = 'CREATE_C2C_PAYMENT', //添加支付方式
  CHANGE_C2C_PAYMENT = 'CHANGE_C2C_PAYMENT', //修改支付方式
}
