export interface PollingOptions {
  interval: number;
  callback: Function;
}

interface PollingInterface extends PollingOptions {
  timer: ReturnType<typeof setTimeout> | null;
  once: boolean;
  start(): void;
  stop(): void;
}

class Polling implements PollingInterface {
  public interval: number;
  public callback: Function;
  public timer: ReturnType<typeof setTimeout> | null;
  public once: boolean = false;

  constructor(options: PollingOptions) {
    this.interval = options.interval;
    this.callback = options.callback;
    this.timer = null;
  }
  private loop() {
    this.timer = setTimeout(async () => {
      // 当前窗口后台运行时，不执行回调
      if (!document?.hidden) {
        await this.callback();
      }
      this.loop();
    }, this.interval);
  }
  // 开启
  public async start(): Promise<void> {
    if (!this.once) {
      this.once = true;
      await this.callback();
      this.loop();
    }
  }

  public stop = (): void => {
    if (this.timer) {
      this.once = false;
      clearTimeout(this.timer);
      this.timer = null;
    }
  };
}

export { Polling };
