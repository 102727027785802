export const CurrencySymbol: { [key: string]: string } = {
  ALL: 'Lek',
  AFN: '؋',
  ARS: '$',
  AWG: 'ƒ',
  AUD: 'A$',
  AZN: '₼',
  BSD: '$',
  BBD: '$',
  BYN: 'B',
  BZD: 'BZ$',
  BMD: '$',
  BOB: '$b',
  BAM: 'KM',
  BWP: 'P',
  BGN: 'лв',
  BRL: 'R$',
  BND: '$',
  KH: '៛',
  CAD: 'C$',
  KYD: '$',
  CLP: '$',
  CNY: '¥',
  COP: '$',
  CRC: '₡',
  HRK: 'kn',
  CUP: '₱',
  CZK: 'Kč',
  DKK: 'k',
  DOP: 'RD$',
  XCD: '$',
  EGP: '£',
  SVC: '$',
  EU: '€',
  FKP: '£',
  FJD: '$',
  GHS: '¢',
  GIP: '£',
  GTQ: 'Q',
  GGP: '£',
  GYD: '$',
  HNL: 'L',
  HKD: 'HKD',
  HUF: 'Ft',
  ISK: 'k',
  IN: '₹',
  ID: 'Rp',
  IR: '﷼',
  IMP: '£',
  ILS: '₪',
  JMD: 'J$',
  JPY: '¥',
  JEP: '£',
  KZT: 'лв',
  KPW: '₩',
  KRW: '₩',
  KGS: 'лв',
  LAK: '₭',
  LBP: '£',
  LRD: '$',
  MKD: 'ден',
  MY: 'RM',
  MU: '₨',
  MXN: '$',
  MNT: 'د.إ',
  MZN: 'MT',
  NAD: '$',
  NP: '₨',
  ANG: 'ƒ',
  NZD: '$',
  NIO: 'C$',
  NGN: '₦',
  NOK: 'k',
  OM: '﷼',
  PK: '₨',
  PAB: 'B/.',
  PYG: 'Gs',
  PEN: 'S/.',
  PHP: '₱',
  PLN: 'zł',
  QA: '﷼',
  RON: 'lei',
  RUB: '₽',
  SHP: '£',
  SA: '﷼',
  RSD: 'Дин.',
  SC: '₨',
  SGD: '$',
  SBD: '$',
  SOS: 'S',
  ZA: '',
  LK: '₨',
  SEK: 'k',
  CHF: 'CHF',
  SRD: '$',
  SYP: '£',
  TWD: 'NT$',
  THB: '฿',
  TTD: 'TT$',
  TRY: '₺',
  TVD: '$',
  UAH: '₴',
  AED: 'د.إ',
  GBP: '£',
  USD: '$',
  UYU: '$U',
  UZS: 'лв',
  VEF: 'Bs',
  VND: '₫',
  YE: '﷼',
  ZWD: 'Z$',
  DZD: 'DZD',
  EUR: '€',
  QAR: 'ر.ق',
  ZAR: 'R',
  IDR: 'Rp',
  SAR: 'ر.س',
  MYR: 'RM',
  NPR: 'Rs',
  INR: '₹',
  UGX: 'USh',
  MDL: 'lei',
};
