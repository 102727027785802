type NS = number | string;
// 现货交易
export const SPOT_GRID = {
  /**
   * @description 计算最高网格利润率：（网格最高价 - 网格次高价 -（网格最高价 + 网格次高价）* makerfee）/网格次高价
   * @description 计算最低网格利润率：（网格次低价 - 网格最低价 -（网格次低价 + 网格最低价）* makerfee）/网格最低价
   * @param {string | number} lowPrice 最低价
   * @param {string | number} highPrice 最高价
   * @param {number} gridCount 网格数
   * @param {number} makerfee 挂单手费率
   */
  getGridProfitRate: (lowPrice: NS, highPrice: NS, gridCount: number, makerfee: number, scale: number) => {
    if (highPrice.sub(lowPrice) == '0') {
      return {
        lowProfit: 0,
        highProfit: 0,
      };
    }
    const spacePrice = highPrice.sub(lowPrice).div(gridCount.toFixed(8));
    let result = [];
    let pointPrice = lowPrice;
    for (let i = 1; i <= gridCount; i++) {
      const newPointPrice = i === gridCount ? highPrice : pointPrice.add(spacePrice);
      const newPointPriceFixed = newPointPrice.toFixed(scale);
      const pointPriceFixed = pointPrice.toFixed(scale);
      const profit = newPointPriceFixed.sub(pointPriceFixed).sub(newPointPriceFixed.add(pointPriceFixed).mul(makerfee)).div(pointPriceFixed);

      pointPrice = newPointPrice;
      result.push(Number(profit));
    }

    return {
      lowProfit: Math.min(...result),
      highProfit: Math.max(...result),
    };
  },
  getInvestmentMinMoney: (price: NS, lowPrice: NS, highPrice: NS, gridCount: number, factor: number, scale: number) => {
    const spacePrice = highPrice.sub(lowPrice).div(gridCount).toFixed(8);

    let matchIndex = -1;
    let arr = [lowPrice];
    let sum = lowPrice;

    for (let i = 1; i <= gridCount; i++) {
      arr[i] = i === gridCount ? highPrice : arr[i - 1].add(spacePrice);
      if (Number(price) > Number(arr[i - 1]) && Number(price) < Number(arr[i])) {
        matchIndex = i;
      }
      sum = sum.add(arr[i].toFixed(scale));
    }

    if (price > highPrice) {
      sum = sum.sub(highPrice);
    } else if (price < lowPrice) {
      sum = sum.sub(lowPrice);
    }

    if (matchIndex > -1) {
      sum = Number(sum.sub(arr[matchIndex].toFixed(scale)));
    }

    return sum.mul(12).div(lowPrice).add(factor);
  },
};
