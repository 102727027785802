import { postCommonSendEmailCodeApi, postCommonValidateEmailCodeApi, sendSmsCodeApi, validateSmsCodeApi } from '@/core/api';
import { R } from '@/core/network';
import { Captcha } from '../captcha';
import { SENCE } from './types';

/**
 * 验证类
 * @class VerifyCode
 * @extends {Captcha}
 */

class VerifyCode extends Captcha {
  // 调用verification接口获取token
  public static getVerificationToken = async () => {
    const { data } = await this.verification();
    return data?.token;
  };
  // 发送邮箱验证码
  public static sendEmail = async <T>(sence: SENCE, email: string, closeVerify: boolean = false): Promise<R<T> | void> => {
    const param = { sence } as any;
    if (sence === SENCE.BIND_EMAIL || sence === SENCE.LOGIN || sence === SENCE.REGISTER || sence === SENCE.FORGOT_PASSWORD || sence === SENCE.REACTIVATE_ACCOUNT) {
      param.email = email;
    }
    if (closeVerify) return postCommonSendEmailCodeApi<T>(param);
    const { data } = await this.verification();
    if (data?.token) {
      return postCommonSendEmailCodeApi<T>({ ...param, token: data.token });
    }
  };
  // 发送提币邮箱验证码
  public static sendWithDrawEmail = async <T>(email: string, withDrawData: any): Promise<R<T> | void> => {
    return postCommonSendEmailCodeApi<T>({
      sence: SENCE.CREATE_WITHDRAW,
      email: email,
      params: withDrawData,
    });
  };

  // 发送手机验证码
  public static sendPhone = async <T>(sence: SENCE, countryCode: number | string, phone: string, closeVerify: boolean = false): Promise<R<T> | void> => {
    const param = { sence } as any;
    if (sence === SENCE.BIND_PHONE || sence === SENCE.LOGIN || sence === SENCE.REGISTER || sence === SENCE.FORGOT_PASSWORD || sence === SENCE.REACTIVATE_ACCOUNT) {
      param.countryCode = countryCode;
      param.phone = phone;
    }
    if (closeVerify) return sendSmsCodeApi<T>(param);
    const { data } = await this.verification();
    if (data?.token) {
      return sendSmsCodeApi<T>({ ...param, token: data?.token });
    }
  };
  // 校验邮箱验证码
  public static checkEmail = async <T>(account: string, code: string): Promise<R<T> | void> => {
    return postCommonValidateEmailCodeApi<T>({ account, type: SENCE.REGISTER, code });
  };
  // 校验短信验证码
  public static checkSmsCode = async <T>(account: string, code: string): Promise<R<T>> => {
    return validateSmsCodeApi<T>({ account, type: SENCE.REGISTER, code });
  };
  // 获取图形验证码token
  public static getCToken = async (): Promise<string> => {
    const { data } = await this.verification();
    return data?.token || '';
  };
}

export { VerifyCode };
