export enum P2PTradeType {
  BUY = 'BUY',
  SELL = 'SELL',
}

export enum P2PTradeSquareType {
  BUY = 'BUY',
  SELL = 'SELL',
}

export interface RequestData {
  tradeType?: string;
  coins?: string;
  coin?: string;
  fiat?: string;
  countries?: string;
  paymethods?: string;
  page?: number;
  rows?: number;
  createTimeGe?: string;
  createTimeLe?: string;
  states?: string;
  state?: number;
}

export interface IUserDetail {
  firstname: string;
  lastname: string;
  merchant: boolean;
  country: string;
  nickname: string;
  fiat: string; // 法币
  identityValid: boolean;
  username: string;
  bindPhone: boolean;
  bindEmail: boolean;
  registerRegion: string;
  progressOrders: string;
  favoriteUsers: number;
  uid: string;
  blockUsers: number;
  registerTime: number;
  stat: {
    buyDoneCount: number; //购买订单成交次数
    sellDoneCount: number; //出售订单总成交次数
    totalCount: number; // 总订单数
    avgPayment: number; // 平均付款时间
    avgConfirm: number; //平均放行时间(s)
  };
  merchantVO: {
    uid: string;
  };
}

export interface IMyPaymentsList {
  id: string;
  uid: string;
  paymethod: string;
  payee: string;
  account: string;
  qrcode: string | File;
  bank: null | string;
  bankAddress: null | string;
  enable: true;
  updateTime: null | string;
  createTime: number;
  name: string;
  tips: string;
}
export interface ISupportFiatList {
  fiat: string;
  icon: string;
  symbol: string;
  amountMax: number;
  amountMin: number;
  enable: boolean;
  id: string;
  scale: number;
}
export interface ISupportCoinList {
  id: number;
  coin: string;
  scale: number;
  fee: number;
  buyQuantityMin: number; //购买委托数量下限
  buyQuantityMax: string; // 购买委托数量上限
  sellQuantityMin: string; // 出售委托数量下限
  sellQuantityMax: string; //出售委托数量上限
  priceRateMin: string; // 浮动价格参数下限
  priceRateMax: string; //浮动价格参数上限
}
export interface IOrdersList {
  list: {
    advId: string;
    amount: number;
    cancelTime: number;
    cancelUid: string;
    cancelWhy: string;
    coin: string;
    complainId: string;
    createTime: number;
    expireTime: number;
    fee: number;
    feeType: number;
    fiat: string;
    finishTime: number;
    id: string;
    isBlock: boolean;
    isComment: boolean;
    merchantName: string;
    merchantUid: string;
    payTime: string | null;
    paymentId: string;
    paymentInfo: { id: string; uid: string; paymethod: string; payee: string; account: string };
    phone: string;
    price: number;
    quantity: number;
    realName: string;
    releaseTime: string;
    remark: string;
    state: number;
    tradeType: string;
    uid: string;
    updateTime: number;
    username: string;
  }[];
  count: string;
  page: number;
  size: number;
  totalPage: number;
}
export type SubmitAdvParam = {
  id?: string;
  buyerRegisterDays: string;
  buyerOrderCount: string;
  buyerKyc: number;
  totalQuantity: string;
  price: number;
  expiration: number;
  tradeType: string;
  coin: string;
  fiat: string;
  priceType: string | number;
  priceRate: string | number;
  weekdays: string[];
  startTime: string;
  endTime: string;
  paymethods: string[];
  paymethodIds: string[];
  remark: string;
  state: number;
  amountMax: number;
  amountMin: number;
  totalAmount: number | string;
};
