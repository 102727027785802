/**
 * 与p2p有关的接口合集
 */

import { http } from '../../http/request';
import { paths } from '../paths';
import { IMyPaymentsList, IOrdersList, ISupportCoinList, ISupportFiatList, IUserDetail, RequestData, SubmitAdvParam } from './types';

/** p2p 广告列表 */
export const getP2PMyAdvApi = (params: { page: number; rows: number; tradeType?: string; coin?: string; fiat?: string; state?: string }) => {
  return http.get(paths['p2p_my_adv'], { params });
};

/** p2p 订单列表 */
export const getP2POrderListApi = (params: RequestData) => {
  return http.get<IOrdersList>(paths['p2p_order_list'], { params });
};

/** p2p 广场广告列表 未登录 */
export const getP2PPublicSearchApi = (params: RequestData) => {
  return http.get(paths['p2p_public_search_list'], { params });
};
/** p2p 广场广告列表 已登录 */
export const getP2PPrivateSearchApi = (params: RequestData) => {
  return http.get(paths['p2p_private_search_list'], { params });
};

/** p2p 资金资产 */
export const getP2PAccountAssetsApi = () => {
  return http.get(paths['p2p_account_assets']);
};

/** p2p他人个人详情 */
export const getP2PUserOtherDetailApi = (uid: string) => {
  return http.get(`${paths['p2p_user_other_detail']}/${uid}`);
};

/** p2p_merchant_apply_detail */
export const getP2PMerchantApplyDetailApi = () => {
  return http.get(paths['p2p_merchant_apply_detail']);
};
/** p2p个人详情 */
export const getP2PUserDetailApi = () => {
  return http.get<IUserDetail>(paths['p2p_user_detail']);
};
/** p2p 支付方式列表 */
export const getP2PMyPaymentsApi = (paymethods?: string) => {
  return http.get<IMyPaymentsList[]>(paths['p2p_my_payments'], { params: { paymethods } });
};
/** p2p 用户关注列表 */
export const getP2PUserFavListApi = (page: number = 1) => {
  return http.get(paths['p2p_user_fav_list'], { params: { rows: 8, page } });
};
/** p2p 用户拉黑列表 */
export const getP2PUserBlackListApi = (page: number = 1) => {
  return http.get(paths['p2p_user_black_list'], { params: { rows: 8, page } });
};

/** 删除支付方式 */
export const deleteP2PPaymentApi = (id: string) => {
  return http.post(`${paths['p2p_delete_payment']}/${id}`);
};

/** 打开/关闭支付方式 */
export const enableP2PPaymentApi = (id: string, enable: boolean) => {
  return http.post(paths['p2p_enable_payment'], { id, enable });
};
/** 用户关注/取消关注
 * @param vid: 关注用户/商户UID
 * @param fav:true-关注 false-取关
 */
export const postP2PUserFavApi = (data: { vid: string; fav: boolean }) => {
  return http.post(paths['p2p_user_fav'], data);
};
/** 用户拉黑/解除拉黑用户/商户
 * @param vid: 关注用户/商户UID
 * @param black:true-拉黑 false-解除拉黑
 */
export const postP2PUserBlockApi = (data: { vid: string; black: boolean }) => {
  return http.post(paths['p2p_user_block'], data);
};

/** 广场支持的支付方式  */
export const getP2PSupportPaymentListApi = () => {
  return http.get(paths['p2p_support_payment_list']);
};

/** 添加支付方式 */
export const addP2PAPaymentsApi = (data: { token: string; paymethod: string; payee: string; account: string; qrcode?: string; bank?: string; bankAddress?: string; enable?: boolean }) => {
  return http.post(paths['p2p_add_payments'], data, { headers: { 'Content-Type': 'multipart/form-data' } });
};
export const changeP2PAPaymentsApi = (data: { token: string; paymethod: string; payee: string; account: string; qrcode?: string; bank?: string; bankAddress?: string; enable?: boolean }) => {
  return http.post(paths['p2p_change_payments'], data, { headers: { 'Content-Type': 'multipart/form-data' } });
};
/** 商户申请要求 */
export const getP2PMerchantConfigApi = () => {
  return http.get(paths['p2p_merchant_config']);
};
/** 支持法币 */
export const getP2PSupportFiatApi = () => {
  return http.get<ISupportFiatList[]>(paths['p2p_support_fiat']);
};
/** 发布广告--支持货币列表 */
export const getP2PSupportCoinApi = () => {
  return http.get<ISupportCoinList[]>(paths['p2p_support_coins_list']);
};
/** 获取交易价格 -- 参考方向 */
export const getP2PQuotedPriceApi = (data: { coin: string; fiat: string; tradeType: string }) => {
  return http.get(paths['p2p_quoted_price'], { params: data });
};
/** 申请成为商户 */
export const postApplyMerchantApi = (data: { country: string; realname: string; contact: string; contactCode: string; level: number }) => {
  return http.post(paths['p2p_apply_merchant'], data, { headers: { 'Content-Type': 'multipart/form-data' } });
};

/** 发布广告 */
export const postP2PSubmitAdvApi = (data: SubmitAdvParam) => {
  return http.post(paths['p2p_submit_adv'], data);
};

/** 发布订单 */
export const postP2PPlaceOrderApi = (data: { advId: string; type: number; totalAmount: number; price: number; paymentId: string; platform: string }) => {
  return http.post(paths['p2p_place_order'], data);
};

/** 上下架广告 */
export const updateP2PAdvStateApi = (data: { advId: string; state: number }) => {
  return http.post(paths['p2p_update_adv_state'], data);
};
/** 撤销广告 */
export const cancelP2PAdvApi = (advId: string) => {
  return http.post(`${paths['p2p_cancel_adv']}/${advId}`);
};
/** 订单详情 */
export const getP2POrderDetailApi = (orderId: number) => {
  return http.get(`${paths['p2p_order_detail']}/${orderId}`);
};
/** 发送消息 */
export const sendP2PMessageApi = (data: { receiver: string; orderId: string; type: string; content?: string; imageFiles?: any }) => {
  return http.post(paths['p2p_send_message'], data, { headers: { 'Content-Type': 'multipart/form-data' } });
};

/** 拿取订单所有消息 */
export const getP2PChatsMessageApi = (orderId: string) => {
  return http.get(`${paths['p2p_get_all_chats']}/${orderId}`);
};

/** 主动取消订单 */
export const cancelP2POrderApi = (data: { orderId: string; reason: string }) => {
  return http.post(paths['p2p_cancel_order'], data);
};

/** 买家确认付款 */
export const postP2PConfirmPaymentApi = (data: { orderId: string }) => {
  return http.post(paths['p2p_confirm_payment'], data);
};
/** 发起申诉 */
export const postP2PAppealCreateApi = (data: { orderId: string; reason: string; imageFiles: any[]; type: string }) => {
  return http.post(paths['p2p_complain_create'], data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
/** 获取申诉进度 */
export const getP2PAppealProgressApi = (complainId: number) => {
  return http.get(`${paths['p2p_complain_progress']}/${complainId}`);
};
/** 补充申诉 */
export const postP2PSupplementEvidenceApi = (data: { complainId: number; orderId: string; content: string; images: string[] }) => {
  return http.post(paths['p2p_supplement_complain'], data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
/** 卖家确认放行 */
export const postP2PSellerConfirmTransferApi = (data: { orderId: string }) => {
  return http.post(paths['p2P_merchant_confirm_transfer'], data);
};
/** 订单评价 */
export const postP2POrderCommentApi = (data: { vid: string; orderId: string; star: number }) => {
  return http.post(paths['p2p_order_comment'], data);
};
/** 获取广告详情 */
export const getP2PAdvDetailApi = (id: string) => {
  return http.get(`${paths['p2p_adv_detail']}/${id}`);
};
/** 他人个人详情 */
export const getP2POtherUserDetailApi = (uid: string) => {
  return http.get(`${paths['p2p_other_user_detail']}/${uid}`);
};
/** 商户广告列表 */
export const getP2PMerchantAdvListApi = (data: { uid: string; tradeType?: string }) => {
  return http.get(paths['p2p_merchant_adv_list'], { params: data });
};
/** 我与它的历史订单 */
export const getP2PEachOtherOrderHistoryListApi = (data: { uid: string; tradeType?: string; page: number; rows: number }) => {
  return http.get(paths['p2p_each_other_order_list'], { params: data });
};

/** 商户信息 */
export const getP2PMerchantInfoApi = (uid: string) => {
  return http.get(`${paths['p2p_merchant_info']}/${uid}`);
};
/** 编辑广告 */
export const postP2PUpdateAdvApi = (data: SubmitAdvParam) => {
  return http.post(paths['p2p_update_adv'], data);
};
/** 进行中的订单 */
export const getP2POrderInProgressApi = () => {
  return http.get(paths['check_order_in_progress']);
};
/** 修改商户信息 */
export const postP2PUpdateMerchantInfoApi = (data: { nickname?: string; logo?: string }) => {
  return http.post(paths['p2p_update_merchant_info'], data, { headers: { 'Content-Type': 'multipart/form-data' } });
};
