import { RED_PACKET_STATUS, RED_PACKET_TYPES } from '@/pages/[locale]/red-packet/constants';

/**
 * 抽奖活动接口合集
 */

import { http } from '../../http/request';
import { paths } from '../paths';

/** 抽奖活动——获取限时任务列表 */
export function getTaskListApi(module: number) {
  return http.get(paths['activity_missions'], { params: { module } });
}

/** 抽奖活动——领取奖励 */
export function getRewardApi(id: string) {
  return http.post(`${paths['variety_activity_collect']}/${id}`);
}

/** 抽奖活动——奖品配置列表 */
export function getRewardListApi(lotteryId: string) {
  return http.get(paths['variety_lottery_prizes'], { params: { lotteryId } });
}

/** 抽奖活动——抽奖次数查询 */
export function getDrawCountApi() {
  return http.get(paths['lottery_get_draw_count']);
}

/** 抽奖活动——开奖 */
export function getRewordApi(lotteryId: string) {
  return http.post(paths['variety_lottery_draw'], { lotteryId, blind: false });
}

/**
 * 红包相关接口
 */

/** 红包 - 拆红包 */
export function openRedPacketApi({ redpacketId, code, phrase }: { redpacketId?: number; code?: string; phrase?: string }) {
  return http.post<{
    fund: RED_PACKET_TYPES;
    currency: string;
    amount: string;
    expireTime?: string | null;
  }>(paths['variety_open_red_packet'], { redpacketId, code, phrase });
}

/**
 * 红包 - 红包详情
 * @returns {
 *  fund: RED_PACKET_TYPES; // 红包的资金类型
 *  state: Omit<RED_PACKET_STATUS, 'IsGrabbed' | 'OnlyNewUser' | 'OnlyJunior'>; // 红包的当前状态（排除 'IsGrabbed' 和 'OnlyNewUser' 和 'OnlyJunior' 状态）
 *  grabbed: boolean; // 是否已经领取过该红包
 *  phrase?: string | null; // 红包的口令（如果有）
 * }
 *
 */
export function getRedPacketDetailApi(code: string) {
  return http.get<{
    fund: RED_PACKET_TYPES;
    state: Omit<RED_PACKET_STATUS, 'IsGrabbed' | 'OnlyNewUser' | 'OnlyJunior'>;
    grabbed: boolean;
    phrase?: string | null;
  }>(`${paths['variety_red_packet_detail']}/${code}`);
}
