export enum WELFARE_TYPE {
  DAILY = 1,
  LIMIT = 2,
}
export type TWelfareLimits = {
  currency: string;
  currentStep: number;
  id: string;
  name: string;
  prize: number;
  requiredDepositAmount: number;
  requiredTradingAmount: number;
  step: number;
  tradingAmount: number;
  depositAmount: number;
};
export type TWelfareTaskList = {
  tickTime: number;
  limitWithdrawed: boolean;
  giftWithdrawed: boolean;
  withdrawTickTime: number;
  gifts: {
    id: string;
    name: string;
    type: number;
    regionType: number;
    prizeMin: number;
    prizeMax: number;
    requiredAmount: number;
    wallet: string;
    currency: string;
    updateTime: number | null;
    createTime: number;
    brand: string;
    prize: number;
    totalAmount: number;
    collectAmount: number;
    state: number;
    tickTime: string;
  }[];
  limits: TWelfareLimits[];
};
export enum WITHDRAW_TYPE {
  NEWBIE_PACK = 1,
  LIMITED_REWARD = 2,
}
export type TCollectUserGiftParam = {
  id: string;
  state: number;
};
