type NS = number | string;
export const SPOT_MARTIN = {
  /**
   * @description 计算投入金额：（首单金额 + 加仓单金额*(加仓金额倍数^0+ 加仓金额倍数^1+ ... +加仓金额倍数^(最大加仓次数-1 ))
   * @param {number} initQuote 首单金额
   * @param {number} safetyQuote 加仓单金额
   * @param {number} safetyQuoteRate 加仓单金额倍数
   * @param {number} safetyCount 最大加仓次数
   */
  getMartinInvestAmount: (initQuote: number, safetyQuote: number, safetyQuoteRate: number, safetyCount: number) => {
    let sum = 0 as NS;
    for (let i = 0; i < safetyCount; i++) {
      sum = sum.add(Math.pow(safetyQuoteRate, i));
    }
    return initQuote.add(safetyQuote.mul(sum)).toCeil(2);
  },

  /**
   * @description 加仓单价格总偏差：跌多少加仓比例*加仓价差倍数^0+跌多少加仓比例*加仓价差倍数^1....+跌多少加仓比例*加仓价差倍数^（最大加仓次数-1)
   * @param {number} triggerRate 跌多少加仓
   * @param {number} safetyPriceRate 加仓价差倍数
   * @param {number} safetyCount 最大加仓次数
   */
  getMartinMinRate: (triggerRate: number, safetyPriceRate: number, safetyCount: number) => {
    let sum = 0 as NS;
    for (let i = 0; i < safetyCount; i++) {
      sum = sum.add(triggerRate.mul(Math.pow(safetyPriceRate, i)));
    }
    return sum;
  },
};
