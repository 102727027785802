// 仓位模式 单项持仓 双项持仓
export const POSITION_MODE = {
  ONE: 'one',
  TWO: 'two',
};
// 下单单位
export const UNIT_MODE = {
  VOL: 'vol',
  COIN: 'coin',
  MARGIN: 'margin',
};

export const SWAP_DEFAULT_WALLET_ID = 'W001';
