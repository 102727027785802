import CryptoJS from 'crypto-js';

/**
 * 登录参数枚举
 */
export enum AuthParams {
  version = '3.0',
}

/**
 * 加密函数
 * @param data  加密数据
 * @returns  {string}  返回字符串base64
 */
export const encryptAuthData = (data: string): string => {
  // TODO: 更换实际的key 等后端提供
  const key = CryptoJS.enc.Utf8.parse(`W$Ch(+==fay8#W'i`);
  const iv = CryptoJS.enc.Utf8.parse('');
  const mode = CryptoJS.mode.ECB;
  const dataHex = CryptoJS.enc.Utf8.parse(data);
  const encrypted = CryptoJS.AES.encrypt(dataHex, key, { iv, mode });
  const encryptedBase64 = CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
  return encryptedBase64;
};

export const decryptAuthData = (encryptedData: string): string => {
  // TODO: 更换实际的key 等后端提供
  const key = CryptoJS.enc.Utf8.parse(`W$Ch(+==fay8#W'i`);
  const iv = CryptoJS.enc.Utf8.parse('');
  const mode = CryptoJS.mode.ECB;

  const encryptedHex = CryptoJS.enc.Base64.parse(encryptedData);
  const encryptedBase64 = CryptoJS.lib.CipherParams.create({
    ciphertext: encryptedHex,
  });

  const decrypted = CryptoJS.AES.decrypt(encryptedBase64, key, { iv, mode });
  const decryptedData = CryptoJS.enc.Utf8.stringify(decrypted);

  return decryptedData;
};
