/**
 * 通过插件获取浏览器指纹
 */
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';
import { getUUID } from './get';
export const fingerprint = {
  _key: '__visitorId__',
  visitorId: '',
  fpPromise: null as any,
  fpget: null as any,
  get: async function (): Promise<String> {
    // 如果是爬虫就跳过
    if (isCrawler()) return 'crawler';
    const value = localStorage.getItem(this._key);
    try {
      if (this.visitorId) return this.visitorId;
      if (value) {
        this.visitorId = value as string;
        return this.visitorId;
      } else {
        // bydfi.com 才会获取指纹
        if (window.location.origin.includes('bydfi.com')) {
          this.fpPromise ??= FingerprintJS.load({
            apiKey: 'v1UAJgEAXMlvex0QfBqX',
            endpoint: ['/yrincCRh25heXFDI/M0I6GNm5kqVc0qd1?region=ap'],
            scriptUrlPattern: ['/yrincCRh25heXFDI/VT4f85nlyw9K2glv?apiKey=<apiKey>&version=<version>&loaderVersion=<loaderVersion>'],
            region: 'ap',
          });
          const fp = await this.fpPromise;
          const result = await (this.fpget ??= fp.get());
          this.visitorId = result.visitorId;
          localStorage.setItem(this._key, this.visitorId);
          console.log('visitorId:', this.visitorId);
          return this.visitorId;
        } else {
          return this._getFingerprint(value);
        }
      }
    } catch (error) {
      return this._getFingerprint(value);
    }
  },
  _getFingerprint: function (value: string | null = null) {
    if (value) {
      this.visitorId = value as string;
      return this.visitorId;
    } else {
      this.visitorId = getUUID(20);
      localStorage.setItem(this._key, this.visitorId);
      return this.visitorId;
    }
  },
};

// 是否是爬虫
const isCrawler = () => {
  try {
    var userAgent = navigator.userAgent.toLowerCase();
    var crawlerKeywords = ['bot', 'crawl', 'spider', 'slurp', 'bingbot', 'googlebot', 'msnbot', 'yandexbot', 'bingpreview'];
    for (var i = 0; i < crawlerKeywords.length; i++) {
      if (userAgent.indexOf(crawlerKeywords[i]) != -1) {
        return true;
      }
    }
    return false;
  } catch (e) {
    return false;
  }
};
